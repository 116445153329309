import React from "react"

// Components
import Hero from "../../../components/hero"

const VisbyDownloadsList = () => {
  return (
    <Hero pageTitle="Subscribe to Visby University updates">
      <p>
      Subscribe for Visby updates.
      </p>
    </Hero>
  )
}
export default VisbyDownloadsList